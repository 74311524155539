<template>
  <div>
    <div v-if="!isLoading">
      <v-container fluid>
        <v-row>
          <v-col col="12">
            <h2>{{ $t('menu.contacts') }}</h2>
          </v-col>

          <v-col cols="12" v-if="hasSearch">
            <v-text-field
              outlined
              append-icon="mdi-magnify"
              :label="$t('contacts.search')"
              :value="searchTerm"
              @input="search"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" lg="4" md="6" v-for="relation in listToDisplay" :key="relation.id">
            <relation
              :relation="relation"
              :is-contact="true"
              :loggedUserId="loggedInUser.id"
              @remove-connection="removeConnection"
              @chat="openChat"
              @go-to-profile="goToProfile"
            />
          </v-col>
        </v-row>
      </v-container>
    </div>

    <loading v-else />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import Relation from '@/components/relation/Relation.vue';
import Loading from '@/components/loading/Loading.vue';

import { MessageType } from '@/models';

import {
  RELATIONS_MODULE,
  GET_RELATIONS,
  REMOVE_FROM_CONTACTS,
  SEARCH_CONTACT,
} from '@/stores/umanize-app/actions/relations/relations.actions';
import { APP_CHATS_MODULE, OPEN_CHAT } from '@/stores/umanize-app/actions/chats/chats.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';

import { DISPLAY_MESSAGE, MESSAGE_MODULE } from '@/stores/shared/actions/message/message.actions';

export default {
  name: 'Contacts',
  components: { Loading, Relation },
  data: () => ({
    hasSearch: false,
  }),
  computed: {
    ...mapGetters(RELATIONS_MODULE, ['isLoading', 'relations', 'filteredContacts', 'searchTerm']),
    ...mapGetters(APP_USER_MODULE, ['loggedInUser']),
    listToDisplay() {
      return this.filteredContacts?.length ? this.filteredContacts : this.relations;
    },
  },
  methods: {
    ...mapActions(RELATIONS_MODULE, [GET_RELATIONS, REMOVE_FROM_CONTACTS, SEARCH_CONTACT]),
    ...mapActions(APP_CHATS_MODULE, [OPEN_CHAT]),
    ...mapActions(MESSAGE_MODULE, [DISPLAY_MESSAGE]),
    async search(name) {
      await this[SEARCH_CONTACT](name);
    },
    async removeConnection(relation) {
      await this[REMOVE_FROM_CONTACTS](relation);
      this[DISPLAY_MESSAGE]({
        text: this.$t('contacts.removed', {
          name: `${relation.firstName} ${relation.lastName}`,
        }),
        messageType: MessageType.info,
      });
    },
    openChat(user) {
      this[OPEN_CHAT]({ userIds: [user.id] });
    },
    goToProfile(userId) {
      this.$router.push({ name: 'PublicProfile', params: { userId } });
    },
  },
  mounted() {
    this[GET_RELATIONS]();
  },
};
</script>

<style lang="scss" scoped></style>
